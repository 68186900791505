import {withStyles} from "@material-ui/core/styles";
import React from "react";
import {
    MAIN_CONTENT_MAX_WIDTH,
    MAIN_CONTENT_MIN_WIDTH,
    MARGIN_SIZE,
    MARGIN_SIZE_SM_DOWN,
    SMALL_DOWN_MEDIA_QUERY,
    XS_DOWN_MEDIA_QUERY
} from "./constants";


const gridStyles = theme => ({
    gridContainer: {
        display: "flex",
        marginLeft: -MARGIN_SIZE,
        alignItems: "stretch",
        flexWrap: "wrap",

        [SMALL_DOWN_MEDIA_QUERY]: {
            flexDirection: "column",
            alignItems: "center",
            marginLeft: -MARGIN_SIZE_SM_DOWN,
        },
        [XS_DOWN_MEDIA_QUERY]: {
            marginLeft: 0,
        }

    },

    // Left column that's meant to flex and be lower priority
    gridColFlex: {

        flex: "1 1 33%",
        paddingLeft: MARGIN_SIZE,
        maxWidth: "33%",
        minWidth: 182,


        [SMALL_DOWN_MEDIA_QUERY]: {
            // Switch to full width
            flex: "0 0 auto",
            width: "100%",
            paddingLeft: MARGIN_SIZE_SM_DOWN,
            maxWidth: MAIN_CONTENT_MAX_WIDTH + MARGIN_SIZE_SM_DOWN,
            minWidth: MAIN_CONTENT_MIN_WIDTH + MARGIN_SIZE_SM_DOWN,
        },
        [XS_DOWN_MEDIA_QUERY]: {
            flex: "0 0 auto",
            width: "100%",
            maxWidth: "100%",
            minWidth: "100%",
            paddingLeft: 0,
        },


    },
    gridColMainContent: {
        // width: 636+48,

        flex: "1 1 66%",
        paddingLeft: MARGIN_SIZE,
        maxWidth: MAIN_CONTENT_MAX_WIDTH + MARGIN_SIZE,
        minWidth: MAIN_CONTENT_MIN_WIDTH + MARGIN_SIZE,

        [SMALL_DOWN_MEDIA_QUERY]: {
            // Stay at full width but adjust padding
            flex: "0 0 auto",
            width: "100%",
            paddingLeft: MARGIN_SIZE_SM_DOWN,
            maxWidth: MAIN_CONTENT_MAX_WIDTH + MARGIN_SIZE_SM_DOWN,
            minWidth: MAIN_CONTENT_MIN_WIDTH + MARGIN_SIZE_SM_DOWN,
        },
        [XS_DOWN_MEDIA_QUERY]: {
            flex: "0 0 auto",
            width: "100%",
            maxWidth: "100%",
            minWidth: "100%",
            paddingLeft: 0,
        },

    },
    gridColQuarter: {
        maxWidth: (MAIN_CONTENT_MAX_WIDTH-MARGIN_SIZE)*0.5+MARGIN_SIZE,
        minWidth: (MAIN_CONTENT_MIN_WIDTH-MARGIN_SIZE)*0.5+MARGIN_SIZE,
        flex: "1 1 30%",
        paddingLeft: MARGIN_SIZE,

        [SMALL_DOWN_MEDIA_QUERY]: {
            flex: "0 0 auto",
            width: "100%",
            maxWidth: MAIN_CONTENT_MAX_WIDTH + 1 * MARGIN_SIZE_SM_DOWN,
            minWidth: MAIN_CONTENT_MIN_WIDTH + 1 * MARGIN_SIZE_SM_DOWN,
            paddingLeft: MARGIN_SIZE_SM_DOWN,
        },
        [XS_DOWN_MEDIA_QUERY]: {
            flex: "0 0 auto",
            width: "100%",
            maxWidth: "100%",
            minWidth: "100%",
            paddingLeft: 0,
        },

    },

    TwoColContainer: {
        columns: 2,
        columnGap: MARGIN_SIZE,
        [SMALL_DOWN_MEDIA_QUERY]: {
            // columnGap: MARGIN_SIZE_SM_DOWN,
            columns: 1
        },
    }
});


export const GridContainer = withStyles(gridStyles)(({children, classes, style, id}) => {
    return (
        <div className={classes.gridContainer} style={style} id={id}>
            {children}
        </div>
    )
})


export const GridColMainContent = withStyles(gridStyles)(({children, classes, ...otherParams}) => {
    return (
        <div className={classes.gridColMainContent} {...otherParams}>
            {children}
        </div>
    )
})


export const GridColLeftContent = withStyles(gridStyles)(({children, classes, ...otherParams}) => {
    return (
        <div className={classes.gridColFlex} {...otherParams}>
            {children}
        </div>
    )
})


export const GridColQuarterContent = withStyles(gridStyles)(({children, classes, style}) => {
    return (
        <div className={classes.gridColQuarter} style={style}>
            {children}
        </div>
    )
})

export const TwoColContainer = withStyles(gridStyles)(({children, classes, style, id}) => {
    return (
        <div className={classes.TwoColContainer} style={style} id={id}>
            {children}
        </div>
    )
})

