import React from "react"
import Footer from "./footer"
import {Header} from "./NavHeaderV2";
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    main: {
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: 1200,
        padding: 0,
        paddingBottom: 16,
    },

});


const Layout = withStyles(styles)(({location, children, className, classes, hideNewsletterForm=false}) => {
    const rootPath = `${__PATH_PREFIX__}/`
    const isHomePage = location.pathname === rootPath


    return (
        <div className={className}>
            <div style={{padding: 0}}>
                <Header isHomePage={isHomePage} location={location}/>
            </div>

            <main className={classes.main} style={{
                marginTop: (isHomePage ? 0 : 48)
            }}>{children}</main>
            <Footer hideNewsletterForm={hideNewsletterForm}/>
        </div>
    )
})

export default Layout
