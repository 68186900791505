import {withStyles} from "@material-ui/core/styles";
import {Header6, Header7} from "./type";
import React from "react";
import {GridColLeftContent, GridColMainContent, GridContainer} from "./GridStuff";
import {
    SMALL_DOWN_MEDIA_QUERY,
} from "./constants";


const styles = theme => ({

    SectionContentTopMargin: {
        height: 36,
        [SMALL_DOWN_MEDIA_QUERY]: {
            display: "none"
        },
    },

});


export const SectionContainer = withStyles(styles)(({
                                                            title,
                                                            subtitle,
                                                            overline,
                                                            children,
                                                            id,
                                                            rowGap = 12,
                                                            classes
                                                        }) => {
    return (
        <GridContainer id={id} style={{rowGap: rowGap}}>
            <GridColLeftContent>
                <div style={{height: 24}}>
                    <hr/>
                </div>
                {overline && (<Header7 style={{marginTop: 12, marginBottom: 0, fontWeight: 400}}>{overline}</Header7>)}
                <Header6 style={{marginTop: 12, marginBottom: 0}}>{title}</Header6>
                {subtitle && (<Header7 style={{marginTop: 12, marginBottom: 0, fontWeight: 400}}>{subtitle}</Header7>)}
                {/*<br/>*/}
            </GridColLeftContent>
            <GridColMainContent>

                <div className={classes.SectionContentTopMargin}/>

                {children}
            </GridColMainContent>
        </GridContainer>
    )
})