import {withStyles} from "@material-ui/core/styles";
import {Body1, Body2, Header7, PBody1, textColorDark, textColorLight} from "./type";
import React from "react";


const styles = theme => ({
    quoteContainer: {
        paddingLeft: 12,
        borderLeft: "1px solid " + textColorDark,
        marginBottom: 12
    },
    quoteText: {
        lineHeight: 1.24,
        fontStyle: "italic",
    },
    quotePerson: {
        fontFamily: "anext",
        lineHeight: 1.08,
        color: textColorLight,

    },

});



export const QuoteBlock = withStyles(styles)(({quoteText, personText, classes}) => {

    return (
        <div
            style={{
                marginBottom: 48,
                breakInside: 'avoid',
                pageBreakInside: 'avoid',
                webkitColumnBreakInside: 'avoid'
            }}
        >
            <div className={classes.quoteContainer}>
                <Body1><span className={classes.quoteText}>"{quoteText}"</span></Body1>
            </div>
            {personText && (<Body2><span className={classes.quotePerson}>{personText}</span></Body2>)}

        </div>
    )
})