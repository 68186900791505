import React from "react"
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    root: {
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: 996+24+24,
        padding: 0,
        paddingLeft: 24,
        paddingRight: 24,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 12,
            paddingRight: 12,
        },
    },
});



const LayoutInner = withStyles(styles)(({location, children, classes, className}) => {
    return (
        <div className={classes.root} style={{}}>
            <div className={className}>
                {children}
            </div>
        </div>
    )
})

export default LayoutInner
