import LayoutInner from "./layoutInnerV2";
import {Button, Grid, withStyles} from "@material-ui/core";
import {Link} from "gatsby";
import React from "react";
import * as PATHS from "../utils/paths";

const headerStyles = theme => ({
    root: {
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: 1200,
        marginTop: 32,
        marginBottom: 32
    },
    logoLink: {
        boxShadow: `none`,
    },
    logoImageHomePage: {
        maxWidth: 208,
        marginBottom: 0,
        [theme.breakpoints.down('xs')]: {
            maxWidth: 176,
        },
    },
    logoImage: {
        maxWidth: 144,
        marginBottom: 0
    },
    contactButtonContainer: {
        textAlign: "right",
    }

});
const _Header = ({classes, isHomePage}) => {
    // location.pathname
    return (<header className={classes.root}>
        <LayoutInner>
            <Grid container>
                <Grid item xs={6} sm={6}>
                    <Link className={classes.logoLink} to={`/`}>
                        <img className={isHomePage ? classes.logoImageHomePage : classes.logoImage}
                             src={"https://3iap.com/cdn/images/3iap-logo-v1.3.svg"}
                             alt={"3iap logo"}
                        />
                    </Link>
                    {isHomePage ? (
                        <div style={{
                            fontSize: 12,
                            marginTop: -4,
                            lineHeight: "16px"
                        }}>Human-Centered Data Design</div>
                    ) : ""}
                    {!isHomePage ? (
                        <div><Link to={PATHS.ABOUT_PAGE}>about</Link> | <Link to={PATHS.SERVICES_PAGE}>services</Link>
                        </div>) : ""}

                </Grid>
                <Grid item className={classes.contactButtonContainer} xs={6} sm={6}>
                    <Link to={PATHS.CONTACT_PAGE} style={{textDecoration: "none"}}>
                        <Button variant={"contained"} color={"primary"}>Get in touch</Button>
                    </Link>
                </Grid>
            </Grid>
        </LayoutInner>
    </header>)

}
export const Header = withStyles(headerStyles)(_Header)