import {withStyles} from "@material-ui/core/styles";
import React from "react";
import {GridColLeftContent, GridColMainContent, GridContainer} from "./GridStuff";
import {

    SMALL_DOWN_MEDIA_QUERY,
} from "./constants";


const styles = theme => ({

    title: {
        fontSize: 88,
        letterSpacing: -1.76,
        lineHeight: 1.08,
        marginTop: 0,
        marginBottom: 6,
        hyphens: "manual",

        [theme.breakpoints.down('sm')]: {
            fontSize: 54,
            letterSpacing: -1.08,
        },
    },
    subtitle: {
        fontSize: 42,
        letterSpacing: -.84,
        lineHeight: 1.08,
        fontWeight: 400,
        marginTop: 0,
        marginBottom: 24,
        hyphens: "manual",
        [theme.breakpoints.down('sm')]: {
            fontSize: 34,
            letterSpacing: -.68,
            // lineHeight: 1.2,
        },
    },

    headImgWrap: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
    },
    headImgHor: {
        display: "none",
        [SMALL_DOWN_MEDIA_QUERY]: {
            display: "block"
        },
    },
    headImgVert: {
        display: "block",
        [SMALL_DOWN_MEDIA_QUERY]: {
            display: "none"
        },
    },

});


export const PageHero = withStyles(styles)(({
                                                title,
                                                subtitle,
                                                children,
                                                classes,
                                                style,
                                                imgURLVertical,
                                                imgURLHorizontal,
                                                imgAspectRatioVertical = 1.61,
                                            }) => {
    return (


        <GridContainer style={style}>
            <GridColLeftContent>
                <div className={classes.headImgWrap} style={{aspectRatio: imgAspectRatioVertical}}>
                    <img className={classes.headImgVert} src={imgURLVertical}/>
                    <img className={classes.headImgHor} src={imgURLHorizontal}/>
                    <br/><br/>


                </div>
            </GridColLeftContent>

            <GridColMainContent>

                {title && (<h1 className={classes.title} dangerouslySetInnerHTML={{__html: title}}/>)}
                {subtitle && (<h2 className={classes.subtitle} dangerouslySetInnerHTML={{__html: subtitle}}/>)}

                {children}

            </GridColMainContent>


        </GridContainer>
    )
})