import React, {useState} from 'react';
import {graphql, Link} from "gatsby"
import {withStyles} from '@material-ui/core/styles';
import Layout from "../../components/layoutV2"
import SEO from "../../components/seo"
import LayoutInner from "../../components/layoutInnerV2";
import * as PATHS from "../../utils/paths";
import "../../utils/index"
import ContactForm from '../../components/ContactForm'
import {Button} from "@material-ui/core";
import {
    GridColQuarterContent,
    GridContainer,
    TwoColContainer
} from "../../components/GridStuff";
import {Header3, PBody1, PBody2} from "../../components/type";
import {PageHero} from "../../components/PageHero";
import {QuoteBlock} from "../../components/QuoteBlock";
import {SectionContainer} from "../../components/SectionContainer";

const styles = theme => ({
    root: {},

    spacer: {
        height: 96,
    },

});



const _Page = ({data, location, classes}) => {
    const siteTitle = data.site.siteMetadata.title

    return (
        <Layout location={location} title={siteTitle} hideNewsletterForm={true}>
            <SEO title={"Workshop: Equitable Epidemiology"} location={{pathname: PATHS.WORKSHOPS_EQUITABLE_EPIDEMIOLOGY}}/>


            <LayoutInner>

                <PageHero
                    imgURLHorizontal={"https://3iap.com/cdn/images/3iap-eqep-head-hor-v03.png"}
                    imgURLVertical={"https://3iap.com/cdn/images/3iap-eqep-head-vert-v03.png"}
                    title={"Equitable Epidemiology"}
                    subtitle={"Deep Dive Workshops for Public Health Communicators"}
                >
                    <PBody1>
                        Can risk ratios be risky? Can proportional mortality rates disproportionately
                        increase
                        mortality? Can adhering to communication conventions undermine adherence?
                    </PBody1>
                    <PBody1>
                        Epidemiology conventions, which may be perfectly suitable
                        published in The Lancet, require different considerations when produced for the
                        public.
                    </PBody1>
                    <PBody1>Please get in touch to book a time or a schedule a custom
                        training.</PBody1>

                    <Link to={"#get-in-touch"} style={{textDecoration: "none"}}>
                        <Button variant={"contained"} color={"primary"} style={{width: "50%"}}>Get in touch</Button>
                    </Link>
                </PageHero>

                <div className={classes.spacer}/>


                <SectionContainer title={"Workshop Overview"} rowGap={48}>
                    <PBody1>
                        Equitable visualizations support healthier populations.
                        Thoughtful data design can play an
                        active role in correcting health misbeliefs and harmful stereotypes, as well as supporting
                        impactful communication goals like risk perception, public support, and behavioral
                        adherence. </PBody1>
                    <PBody1>On the other hand, conventional ways of visualizing group health
                        outcomes can be actively harmful.</PBody1>
                    <PBody1>
                        In this workshop, we’ll learn how to visualize population health
                        to support health outcomes
                        that are more equitable for communities of focus, and more effective for the whole
                        population in general.</PBody1>
                    <br/>

                    <TwoColContainer>
                        <QuoteBlock
                            quoteText={"Throughout the rest of our conference, we constantly heard people talking about how enlightening and thought-provoking they found your talk and how they will change their trainings and practices because of it."}
                            personText={"Regional HIV Advocacy & Education Organizer"}/>
                        <QuoteBlock
                            quoteText={"Hearing about this continues to make me consciously examine my choices and think about what I'm doing and showing. Thank you."}
                            personText={"Strategy & Performance Manager, Transforming Aboriginal Outcomes, NSW Department of Communities and Justice"}/>
                        <QuoteBlock quoteText={"Thank you. This was fantastic (and unsettling)."}
                                    personText={"SFDPH Analyst"}/>

                    </TwoColContainer>

                </SectionContainer>


                <div className={classes.spacer}/>
                <SectionContainer title={"Learning Objectives"}>


                    <PBody1>After these workshops, health
                        communicators will:</PBody1>


                    <TwoColContainer style={{rowGap: 24}}>
                        <PBody2>
                            1. Understand how data visualization can impact health communication goals like
                            perceived vulnerability, public support for health policies,
                            behavioral intent, and attributions to social determinants of health.
                        </PBody2>
                        <PBody2>
                            2. Appreciate how popular
                            conventions for visualizing group outcomes can
                            reinforce harmful misbeliefs about population health,
                            and how these misbeliefs can actually undermine health outcomes for the
                            populations being visualized.
                        </PBody2>
                        <PBody2>
                            3. Make alternative
                            chart choices to more effectively, equitably convey
                            population health outcomes.
                        </PBody2>
                    </TwoColContainer>

                </SectionContainer>

                <div className={classes.spacer}/>

                <img style={{maxWidth: "100%"}}
                     src={"https://3iap.com/cdn/images/3iap-workshop-equitable-epidemiology-sample-slides-preview.png"}/>
                <figcaption>A random sample of slides.</figcaption>

                <div className={classes.spacer}/>


                <SectionContainer title={"Intended Audience"}>
                    <PBody1>The workshops are for health communicators involved in producing
                        public-facing
                        visualizations of population health outcomes, in particular:</PBody1>

                    <PBody1>
                            <ul>
                                <li>Analysts, educators, communicators, and administrators at municipal, state, or
                                    federal health agencies</li>
                                <li>Advocacy groups using data to promote health equity</li>
                                <li>Data journalists covering health policy</li>
                            </ul>
                    </PBody1>


                </SectionContainer>
                <div className={classes.spacer}/>


                <SectionContainer title={"Format and Structure"}>
                    <PBody1>
                        Material can be adapted based on your groups’ needs and interests, to cover various themes.
                        Each session requires 60-90 minutes, including a presentation of the topics, supporting
                        example redesigns, questions and discussion, and group exercises.

                        Workshop case studies can also be customized to handle your specific data and use cases.
                    </PBody1>
                </SectionContainer>

                <div className={classes.spacer}/>

                <SectionContainer title={"Pricing"}>
                    <PBody1>
                        Recommended rates are as follows, however pricing can be flexible to meet your organization's
                        needs.
                    </PBody1>
                    <GridContainer style={{rowGap: 24}}>
                        <GridColQuarterContent>
                            <PBody2><b>Large Group Talk:</b> To motivate the topic and
                                introduce the concepts to the wider organization:</PBody2>
                            <PBody2><ul>
                                <li>Single 45 minute primer talk, plus two case studies and Q&A</li>
                                <li>$2,000 per 100 attendees</li>
                            </ul></PBody2>
                        </GridColQuarterContent>
                        <GridColQuarterContent>
                            <PBody2><b>Interactive Workshops:</b> To support group
                                discussion and explore more targeted topics in greater depth:</PBody2>
                            <PBody2><ul>
                                <li>Two to four deep dive sessions, including interactive group exercises and
                                    discussions
                                </li>
                                <li>$59 per participant per session, for 10–30 participants</li>
                            </ul></PBody2>
                        </GridColQuarterContent>

                    </GridContainer>


                </SectionContainer>

                <div className={classes.spacer}/>



                <a id={"get-in-touch"}/>
                <SectionContainer title={"Next Steps"}>

                    <PBody1>Please get in touch to book a time or a schedule a custom
                        training.</PBody1>

                    <PBody1>
                        <ul>
                            <li>Email <a href={"mailto:hi+workshops@3iap.com"}>hi+workshops@3iap.com</a><br/></li>
                            <li>Book a time on <a href={"https://calendly.com/3iap/intro-call"}>3iap's Calendly</a></li>
                            <li>Or use the form below to send a message</li>
                        </ul>
                    </PBody1>
                    <br/><br/>


                    <ContactForm layoutComponent={({children}) => (<div>{children}</div>)}/>
                </SectionContainer>


            </LayoutInner>


        </Layout>
    )
}

export const Page = withStyles(styles)(_Page)
export default Page

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
