import {withStyles} from "@material-ui/core/styles";
import React from "react";

export const textColorDark = "#090E11"
export const textColorLight = "#4D4D4D"

const typeStyles = theme => ({
    body1: {
        color: textColorDark,
        fontFamily: "itext",
        fontSize: 21,
        lineHeight: 1.48,
        hyphens: "manual",
        "& ul": {
            paddingInlineStart: 16,
        },
        "& li": {
            lineHeight: 1.24,
            marginBottom: 12,
        }
    },
    body2: {
        color: textColorDark,
        fontFamily: "itext",
        fontSize: 16,
        lineHeight: 1.48,
        hyphens: "manual",
        "& ul": {
            paddingInlineStart: 16,
        },
        "& li": {
            lineHeight: 1.24,
            marginBottom: 12,
        }
    },
    h3: {
        color: textColorDark,
        fontFamily: "anext",
        fontWeight: 600,
        fontSize: 54,
        lineHeight: 1.08,
        letterSpacing: -1.08,
        hyphens: "manual",
    },
    h4: {
        color: textColorDark,
        fontFamily: "anext",
        fontWeight: 600,
        fontSize: 43,
        lineHeight: 1.08,
        letterSpacing: -.86,
        hyphens: "manual",
    },
    h5: {
        color: textColorDark,
        fontFamily: "anext",
        fontWeight: 600,
        fontSize: 34,
        lineHeight: 1.08,
        letterSpacing: -.68,
        hyphens: "manual",
    },
    h6: {
        color: textColorDark,
        fontFamily: "anext",
        fontWeight: 600,
        fontSize: 26,
        lineHeight: 1.08,
        letterSpacing: -.52,
        hyphens: "manual",
    },
    h7: {
        color: textColorDark,
        fontFamily: "anext",
        fontWeight: 600,
        fontSize: 21,
        lineHeight: 1.08,
        letterSpacing: -.42,
        hyphens: "manual",
    },

});

export const Body1 = withStyles(typeStyles)(({children, classes, ...otherParams}) => {
    return (
        <div className={classes.body1} {...otherParams}>{children}</div>
    )
})

export const PBody1 = withStyles(typeStyles)(({children, classes, ...otherParams}) => {
    return (
        <p className={classes.body1} {...otherParams}>{children}</p>
    )
})

export const Body2 = withStyles(typeStyles)(({children, classes, ...otherParams}) => {
    return (
        <div className={classes.body2} {...otherParams}>{children}</div>
    )
})

export const PBody2 = withStyles(typeStyles)(({children, classes, ...otherParams}) => {
    return (
        <p className={classes.body2} {...otherParams}>{children}</p>
    )
})

export const Header3 = withStyles(typeStyles)(({children, classes, ...otherParams}) => {
    return (
        <div className={classes.h3} {...otherParams}>{children}</div>
    )
})

export const Header4 = withStyles(typeStyles)(({children, classes, ...otherParams}) => {
    return (
        <div className={classes.h4} {...otherParams}>{children}</div>
    )
})

export const Header5 = withStyles(typeStyles)(({children, classes, ...otherParams}) => {
    return (
        <div className={classes.h5} {...otherParams}>{children}</div>
    )
})

export const Header6 = withStyles(typeStyles)(({children, classes, ...otherParams}) => {
    return (
        <div className={classes.h6} {...otherParams}>{children}</div>
    )
})

export const Header7 = withStyles(typeStyles)(({children, classes, ...otherParams}) => {
    return (
        <div className={classes.h7} {...otherParams}>{children}</div>
    )
})